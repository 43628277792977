import * as React from "react";
const SvgTeacher6 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="1em"
    height="1em"
    viewBox="0 0 598.111 535.114"
    {...props}
  >
    <path
      d="M3.352 120.074A4.594 4.594 0 0 0 .17 125.73L76.886 399.71a4.594 4.594 0 0 0 5.656 3.181l282.815-79.19a4.594 4.594 0 0 0 3.182-5.657L291.823 44.065a4.594 4.594 0 0 0-5.657-3.182Z"
      fill="#e6e6e6"
    />
    <path
      d="M86.097 389.953 355.6 314.489l-72.99-260.668-269.504 75.464Z"
      fill="#fff"
    />
    <path
      d="M48.74 164.091c-1.799.504-2.54 3.49-1.653 6.658s3.071 5.335 4.87 4.832l122.917-34.418c1.798-.504 2.539-3.49 1.652-6.658s-3.072-5.335-4.87-4.832ZM58.638 199.443c-1.798.504-2.54 3.49-1.652 6.658s3.071 5.335 4.87 4.831l122.916-34.417c1.798-.504 2.54-3.49 1.653-6.658s-3.072-5.335-4.87-4.832ZM68.423 234.387c-1.798.504-2.54 3.49-1.653 6.658s3.072 5.335 4.87 4.832l122.917-34.418c1.798-.503 2.54-3.49 1.653-6.658s-3.072-5.335-4.87-4.831ZM78.322 269.74c-1.798.503-2.54 3.49-1.653 6.657s3.072 5.335 4.87 4.832l122.917-34.418c1.798-.504 2.54-3.49 1.652-6.658s-3.071-5.335-4.87-4.832ZM234.036 112.614a5.966 5.966 0 1 0 3.217 11.49l22.978-6.434a5.966 5.966 0 0 0-3.217-11.49ZM243.742 147.281a5.966 5.966 0 1 0 3.218 11.49l22.978-6.435a5.966 5.966 0 0 0-3.217-11.49ZM253.45 181.948a5.966 5.966 0 1 0 3.217 11.49l22.978-6.435a5.966 5.966 0 0 0-3.217-11.49ZM263.156 216.614a5.966 5.966 0 0 0 3.217 11.49l22.98-6.435a5.966 5.966 0 0 0-3.218-11.489Z"
      fill="#e6e6e6"
    />
    <path
      d="M272.43 276.703a7.602 7.602 0 1 0 4.1 14.64l29.28-8.198a7.602 7.602 0 0 0-4.1-14.64Z"
      fill="currentColor"
    />
    <path
      fill="#e6e6e6"
      d="m85.898 307.805 216.666-60.668.54 1.926L86.436 309.73z"
    />
    <path
      fill="#a0616a"
      d="m520.202 506.072-17.377 4.201-24.473-65.026 25.647-6.2 16.203 67.025z"
    />
    <path
      d="m472.845 535.114-.117-.486a22.233 22.233 0 0 1 16.367-26.805l33.999-8.219 5.336 22.073Z"
      fill="#2f2e41"
    />
    <path
      fill="#a0616a"
      d="m443.276 517.91-17.877-.001-8.505-68.956 26.385.001-.003 68.956z"
    />
    <path
      d="m447.606 535.01-57.186-.002v-.5a22.208 22.208 0 0 1 22.208-22.207h.001l34.978.001ZM416.88 490.99 399.522 284.12l71.856-13.245.283-.051 21.032 13.52-7.32 76.133 33.704 118.7-29.102 7.658-33.758-110.086-7.726-33.477-3.959 43.5 2.94 107.278Z"
      fill="#2f2e41"
    />
    <path
      d="m397.296 288.811-.19-.24 24.83-186.958.033-.245.175-.175c.366-.366 9.065-8.963 18.014-8.963 1.294 0 2.524-.033 3.703-.062 6.848-.178 12.257-.32 18.686 6.109 6.55 6.549 27.92 30.464 27.92 63.219 0 31.703 2.886 130.227 2.916 131.219l.04 1.39-1.166-.758c-.288-.185-29.031-18.487-53.137-1.474-7.533 5.317-14.3 7.181-20.087 7.181-13.468 0-21.622-10.096-21.737-10.243Z"
      fill="currentColor"
    />
    <circle
      cx={737.302}
      cy={227.82}
      r={35.815}
      transform="rotate(-28.663 229.776 725.567)"
      fill="#a0616a"
    />
    <path
      d="M381.53 328.99a14.664 14.664 0 0 0 .85-22.469l20.34-47.976-26.631 4.901-15.232 44.808a14.743 14.743 0 0 0 20.673 20.736Z"
      fill="#a0616a"
    />
    <path
      d="m361.88 291.67 6.549-13.827a2.693 2.693 0 0 1-.967-1.002c-6.12-10.605 30.842-98.672 33.306-104.514-.376-3.177-4.256-36.844-1.419-48.192 3.34-13.36 10.197-19.585 22.93-20.819 14.041-1.318 17.83 17.75 17.866 17.943l.013 49.02-16.115 56.428-36.752 74.973Z"
      fill="currentColor"
    />
    <path
      d="M440.937 58.87c-4.294.56-7.532-3.833-9.034-7.894s-2.647-8.788-6.38-10.981c-5.1-2.997-11.625.607-17.456-.382-6.586-1.116-10.869-8.096-11.204-14.768s2.32-13.088 4.924-19.239l.91 7.645A15.16 15.16 0 0 1 409.32 0l-1.172 11.217c.735-6.284 7.505-11.153 13.696-9.851l-.184 6.683c7.607-.904 15.28-1.81 22.91-1.12s15.311 3.102 21.094 8.127c8.65 7.516 11.808 19.891 10.748 31.301s-5.77 22.128-10.68 32.482c-1.235 2.605-2.943 5.545-5.807 5.877-2.573.298-4.928-1.853-5.727-4.317s-.41-5.14.069-7.686c.724-3.846 1.636-7.777.956-11.63s-3.453-7.662-7.338-8.135-7.86 3.969-5.992 7.407Z"
      fill="#2f2e41"
    />
    <path
      fill="#3f3d56"
      d="M597.729 535.092H339.991v-2.106h258.12l-.382 2.106z"
    />
  </svg>
);
export default SvgTeacher6;
