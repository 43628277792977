import * as React from "react";
const SvgNotFound = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="1em"
    height="1em"
    viewBox="0 0 982.487 763.014"
    aria-labelledby="searchImgTitle"
    role="img"
    {...props}
  >
    <title id="searchImgTitle">
      Mulher sentada olhando um celular e atrás tem uma lupa e a imagem de um
      planeta com anéis
    </title>
    <circle cx={559.433} cy={333.061} r={40.03} fill="#e6e6e6" />
    <path
      d="m657.539 384.502-41.832-41.832a44.307 44.307 0 0 0-14.427-61.926 44.458 44.458 0 0 0-58.913 12.238 44.307 44.307 0 0 0 60.033 62.995l41.832 41.832a9.41 9.41 0 0 0 13.307-13.307Zm-56.701-43.394a31.684 31.684 0 1 1 9.28-22.404 31.586 31.586 0 0 1-9.28 22.404Z"
      fill="#3f3d56"
    />
    <path
      d="M960.773 446.206c106.98 118.308-203.138 288.808-453.72 288.808S83.107 602.907 53.333 446.206c-72.19-379.947 615.078-621.44 453.72-288.808-235.198 484.848 369.47 195.637 453.72 288.808Z"
      fill="#e6e6e6"
    />
    <path
      d="M912.773 474.206c106.98 118.308-203.138 288.808-453.72 288.808S35.107 630.907 5.333 474.206c-72.19-379.947 615.078-621.44 453.72-288.808-235.198 484.848 369.47 195.637 453.72 288.808Z"
      fill="#3f3d56"
    />
    <path
      d="M149.594 196.825c-75.067-3.305-133.271-19.498-132.507-36.864.459-10.412 21.703-18.784 58.287-22.97a1 1 0 1 1 .227 1.987c-34.461 3.943-56.117 12.017-56.516 21.07-.691 15.705 59.115 31.632 130.597 34.78s132.456-7.462 133.148-23.166c.4-9.092-20.597-19.066-54.798-26.029a1 1 0 1 1 .4-1.96c36.3 7.39 56.856 17.624 56.396 28.077-.765 17.366-60.167 28.38-135.234 25.075ZM82.923 199.734a76.983 76.983 0 0 0 133.036 5.522 1211.976 1211.976 0 0 1-133.036-5.522Z"
      fill="currentColor"
    />
    <path
      d="M224.263 184.22a76.997 76.997 0 1 0-147.75-5.705 572.233 572.233 0 0 0 147.75 5.706Z"
      fill="currentColor"
    />
    <circle cx={168.834} cy={123.601} r={9} fill="#fff" />
    <circle cx={122.513} cy={152.591} r={15} fill="#fff" />
    <circle cx={348.905} cy={142.898} r={10} fill="currentColor" />
    <circle cx={122.905} cy={452.898} r={6} fill="#e6e6e6" />
    <circle cx={542.905} cy={658.898} r={6} fill="#e6e6e6" />
    <circle cx={151.905} cy={339.898} r={3} fill="#e6e6e6" />
    <circle cx={399.905} cy={563.898} r={3} fill="#e6e6e6" />
    <circle cx={850.905} cy={534.898} r={3} fill="#e6e6e6" />
    <circle cx={709.905} cy={623.898} r={3} fill="#e6e6e6" />
    <circle cx={562.905} cy={515.898} r={3} fill="#e6e6e6" />
    <circle cx={372.905} cy={219.898} r={3} fill="#e6e6e6" />
    <circle cx={396.905} cy={71.898} r={3} fill="#e6e6e6" />
    <circle cx={382.905} cy={694.898} r={3} fill="#e6e6e6" />
    <circle cx={234.905} cy={631.898} r={3} fill="#e6e6e6" />
    <circle cx={703.905} cy={523.898} r={3} fill="#e6e6e6" />
    <circle cx={148.374} cy={224.799} r={9} fill="#fff" />
    <path
      d="M351.905 362.898c0 26.88-41.56 89.516-53.705 107.247a3.992 3.992 0 0 1-6.59 0c-12.146-17.73-53.705-80.366-53.705-107.247a57 57 0 1 1 114 0Z"
      fill="#fff"
    />
    <circle cx={294.905} cy={358.898} r={29} fill="currentColor" />
    <ellipse cx={294.405} cy={496.398} rx={33.5} ry={6.5} fill="#fff" />
    <path
      d="M816.853 317.92a10.527 10.527 0 0 0-.64 1.528l-47.784 13.277-8.61-8.427-14.416 11.333 13.794 16.4a8 8 0 0 0 9.352 2.168l51.617-22.784a10.497 10.497 0 1 0-3.313-13.495Z"
      fill="#ffb8b8"
    />
    <path
      d="m766.746 333.503-17.936 13.96a4.5 4.5 0 0 1-6.681-1.338l-11.147-19.727a12.497 12.497 0 0 1 19.77-15.294l16.338 15.594a4.5 4.5 0 0 1-.344 6.805Z"
      fill="currentColor"
    />
    <path
      d="m821.435 311.317 18.188 8.183a3.15 3.15 0 0 1 1.58 4.163l-2.153 4.787a1.053 1.053 0 0 1 .526 1.388l-.861 1.914a1.053 1.053 0 0 1-1.388.527l-.861 1.914a1.053 1.053 0 0 1 .526 1.388l-.861 1.915a1.053 1.053 0 0 1-1.388.527l-11.628 25.846a3.15 3.15 0 0 1-4.164 1.58l-18.188-8.183a3.15 3.15 0 0 1-1.58-4.164l18.088-40.205a3.15 3.15 0 0 1 4.164-1.58Z"
      fill="#3f3d56"
    />
    <path
      d="m804.065 354.719 14.59 6.57a3.034 3.034 0 0 0 4-1.51l6.739-14.99 2.34-5.196 6.1-13.562a3.026 3.026 0 0 0-1.512-3.989l-4.587-2.068-10.014-4.503a3.021 3.021 0 0 0-3.989 1.522l-5.9 13.11-6.717 14.927-2.561 5.7a3.022 3.022 0 0 0 1.511 3.989Z"
      fill="#f2f2f2"
    />
    <path
      fill="#ffb8b8"
      d="m773.577 540.443 12.26-.001 5.833-47.288-18.095.001.002 47.288z"
    />
    <path
      d="M770.45 536.44h24.145a15.386 15.386 0 0 1 15.387 15.386v.5l-39.531.001Z"
      fill="#2f2e41"
    />
    <path
      fill="#ffb8b8"
      d="m805.577 537.443 12.26-.001 5.833-47.288-18.095.001.002 47.288z"
    />
    <path
      d="M802.45 533.44h24.145a15.386 15.386 0 0 1 15.387 15.386v.5l-39.531.001ZM718.585 429.478a39.41 39.41 0 0 0 36.9 26.81l.62.01c6.33.18 13.76-1.86 21.05-4.79 14.4-5.79 28.23-15.06 31.9-17.61l-6.22 59.79-2.52 24.24a4.514 4.514 0 0 0 4.48 4.97h15.53a4.505 4.505 0 0 0 4.36-3.36l25.64-97.65a19.032 19.032 0 0 0-19.71-23.83l-53.26 3.65 3.72-14.88-47.54-3.16-.14.1c-1.14.83-2.25 1.7-3.31 2.61a40.271 40.271 0 0 0-6.99 7.66 38.487 38.487 0 0 0-4.51 35.44Z"
      fill="#2f2e41"
    />
    <path
      d="M718.585 429.478a39.41 39.41 0 0 0 36.9 26.81 196.146 196.146 0 0 0 22.57-13.39l-.9 8.61-7.84 75.42a4.514 4.514 0 0 0 4.48 4.97h15.53a4.505 4.505 0 0 0 4.36-3.36l9.15-34.85 16.49-62.8a19.032 19.032 0 0 0-19.71-23.83l-53.26 3.65 3.72-14.88-26.98-1.79a38.487 38.487 0 0 0-4.51 35.44Z"
      fill="#2f2e41"
    />
    <path
      d="m730.017 389.828-.358-.043L721.365 349c-.07-.354-6.852-35.533 13.937-53.925l.362-2.517a4.5 4.5 0 0 1 5.787-3.657l19.585 6.074a4.49 4.49 0 0 1 3.019 5.442l-1.904 7.242c2.64 3.1 33.715 40.512 22.89 69.335l-4.848 18.952Z"
      fill="currentColor"
    />
    <path
      d="M811.338 357.997a10.526 10.526 0 0 0-1.33.99l-47.897-12.858-3.131-11.634-18.176 2.431 3.542 21.135a8 8 0 0 0 6.952 6.622l56.032 6.62a10.497 10.497 0 1 0 4.008-13.306Z"
      fill="#ffb8b8"
    />
    <path
      d="m760.266 345.944-22.542 2.903a4.5 4.5 0 0 1-5.074-4.548l.43-22.655a12.497 12.497 0 0 1 24.8-3.12l6.141 21.734a4.5 4.5 0 0 1-3.755 5.686Z"
      fill="currentColor"
    />
    <circle cx={753.921} cy={258.804} r={24.561} fill="#ffb8b8" />
    <path
      d="m752.628 260.855-3.075-5.6c-5.824 23.732 4.464 43.373 4.464 43.373l-39.932-18.6.38-6.6-3.951 4.484-5.678-3.13-.75-4.28-4.285 1.062 15.643-28.388c15.183-27.013 37.255-16.7 37.255-16.7 35.116-1.669 30.827 32.976 30.827 32.976Z"
      fill="#2f2e41"
    />
    <ellipse
      cx={556.161}
      cy={472.391}
      rx={12.179}
      ry={47.5}
      transform="rotate(-32.662 384.906 623.728)"
      fill="#3f3d56"
    />
  </svg>
);
export default SvgNotFound;
