import * as React from "react";
const SvgTeacher1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="1em"
    height="1em"
    viewBox="0 0 751.131 489.52"
    {...props}
  >
    <path
      d="m174.755 428.004-22.059-7.707a75.393 75.393 0 0 1 1.587-36.24c8.368 20.146 34.469 25.779 48.961 42.086a45.35 45.35 0 0 1 10.509 37.877l4.224 16.025a75.991 75.991 0 0 1-54.372-33.048 73.404 73.404 0 0 1-8.126-16.525c9.522-.675 19.276-2.468 19.276-2.468Z"
      fill="#f2f2f2"
    />
    <path
      d="M430.896 284.768h-115.51a6.163 6.163 0 0 0-6.154 6.17v182.471h127.834V290.937a6.166 6.166 0 0 0-6.17-6.17ZM373.53 388.446a13.704 13.704 0 0 1-13.64-13.64V353.76a13.64 13.64 0 0 1 27.28 0v21.046a13.704 13.704 0 0 1-13.64 13.64Z"
      fill="#e6e6e6"
    />
    <path
      d="M308.836 467.573v17.605a3.449 3.449 0 0 0 3.441 3.441h121.744a3.459 3.459 0 0 0 3.441-3.441v-17.605Z"
      fill="#ccc"
    />
    <path
      d="M622.17 0H128.41a16.029 16.029 0 0 0-16.003 16.003v333.191a16.018 16.018 0 0 0 16.003 16.003h493.76a16.018 16.018 0 0 0 16.003-16.003V16.003A16.029 16.029 0 0 0 622.17 0Z"
      fill="#e6e6e6"
    />
    <path
      d="M618.047 11.554h-485.51a8.58 8.58 0 0 0-8.56 8.58v324.93a8.57 8.57 0 0 0 8.56 8.56h485.51a8.57 8.57 0 0 0 8.56-8.56V20.134a8.58 8.58 0 0 0-8.56-8.58Z"
      fill="#fff"
    />
    <path
      d="M629.526 18.905v282.82a179.022 179.022 0 0 1-183.37-291.4h174.81a8.58 8.58 0 0 1 8.56 8.58Z"
      fill="currentColor"
    />
    <path
      d="M593.643 56.327H139.35a1.807 1.807 0 0 1 0-3.613h454.293a1.807 1.807 0 0 1 0 3.613Z"
      fill="#cacaca"
    />
    <ellipse cx={170.274} cy={32.666} rx={10.588} ry={10.823} fill="#3f3d56" />
    <ellipse cx={206.849} cy={32.666} rx={10.588} ry={10.823} fill="#3f3d56" />
    <ellipse cx={243.424} cy={32.666} rx={10.588} ry={10.823} fill="#3f3d56" />
    <path
      d="M571.622 24.526H545.64a1.968 1.968 0 0 0 0 3.934h25.98a1.968 1.968 0 0 0 0-3.934ZM571.622 31.91H545.64a1.968 1.968 0 0 0 0 3.935h25.98a1.968 1.968 0 0 0 0-3.935ZM571.622 39.285H545.64a1.968 1.968 0 0 0 0 3.934h25.98a1.968 1.968 0 0 0 0-3.934Z"
      fill="#3f3d56"
    />
    <path
      d="M253.96 254.283a6.76 6.76 0 0 0-1.349-10.278l5.54-23.374-11.725 4.283-3.463 21.54a6.797 6.797 0 0 0 10.996 7.829Z"
      fill="#9e616a"
    />
    <path
      d="m282.156 156.33-4.706 12.1s2.017 12.1-1.344 14.79-2.017 2.689-2.017 4.705a10.34 10.34 0 0 1-4.033 7.395c-2.69 2.017-14.79 43.023-14.79 43.023h-12.1a37.912 37.912 0 0 0 0-9.411c-.672-4.706 2.017-16.806 3.361-18.823s0-4.705 0-8.739 2.69-6.05 2.69-12.772 8.066-59.158 7.394-65.208 6.722-11.428 6.722-11.428h6.723Z"
      fill="#e6e6e6"
    />
    <path
      fill="#9e616a"
      d="M325.812 471.541h9.315l4.433-35.93h-13.748v35.93z"
    />
    <path
      d="m322.772 465.16 14.882-.888v6.377l14.149 9.771a3.983 3.983 0 0 1-2.264 7.26h-17.717l-3.054-6.307-1.192 6.307h-6.68Z"
      fill="#2f2e41"
    />
    <path
      fill="#9e616a"
      d="m209.063 463.627 8.655 3.445 17.407-31.742-12.773-5.085-13.289 33.382z"
    />
    <path
      d="m208.598 456.575 14.156 4.679-2.359 5.924 9.532 14.311a3.983 3.983 0 0 1-4.788 5.909l-16.461-6.553-.505-6.99-3.44 5.42-6.207-2.471ZM307.036 364.628l-15.533-72.488-24.256 80.54-38.407 76.373-.204.405-12.166-6.55c-1.717-31.01 2.361-60.07 17.276-85.48l7.063-69.747c.216-2.383 5.484-58.331 22.166-68.253l4.885-11.14 48.59-12 .177.2a37.147 37.147 0 0 1 8.9 18.656l14.52 87.957.008.049-3.893 61.36 5.325 90.525h-19.268q-17.88-33.642-15.183-90.407ZM352.98 123.22l29.457-10.306a3.652 3.652 0 0 1 2.411 6.894l-29.456 10.305a3.652 3.652 0 0 1-2.412-6.894Z"
      fill="#2f2e41"
    />
    <path
      d="M384.63 118.87c.577 1.995 3.063 2.964 5.07 2.432a8.24 8.24 0 0 0 4.617-3.95c1.079-1.774 1.82-3.74 2.929-5.494a15.412 15.412 0 0 1 9.302-6.67 16.142 16.142 0 0 0-9.923 1.88 11.978 11.978 0 0 1-3.476 1.638 30.055 30.055 0 0 1-3.968-.052 6.123 6.123 0 0 0-4.158 10.3"
      fill="currentColor"
    />
    <path
      d="M380.778 122.043a6.76 6.76 0 0 1-8.718 5.606l-14.274 19.322-4.299-11.72 14.214-16.552a6.797 6.797 0 0 1 13.077 3.344Z"
      fill="#9e616a"
    />
    <path
      d="M253.538 231.888c-1.308 0-2.052-.257-2.34-.807-.422-.807.311-1.862 1.16-3.085.591-.851 2.32-2.545 1.47-2.648-12.89-1.574-6.702-81.044-1.116-91.549 1.135-2.134-.066-5.409 1.212-9.03 3.491-9.893 8.257-12.933 17.75-18.988q1.157-.738 2.408-1.541c1.08-.694 1.02-2.993.956-5.428-.07-2.707-.144-5.506 1.182-7.255l.086-.114.14-.033c3.206-.74 13.993-2.117 20.38.01l.089.03.066.066c1.517 1.517 1.655 4.457 1.79 7.299.109 2.333.212 4.536 1.07 5.494 6.564 7.315 14.007 7.173 14.083 7.171l.369-.01.036.366c4.602 8.758.999 41.285 4.197 61.49 3.25 20.526 31 38 7.238 41.102a.515.515 0 0 1 .016.533c-1.435 2.683-67.31 16.927-72.242 16.927Zm71.566-17.029Z"
      fill="#e6e6e6"
    />
    <path
      d="m328.758 172.108-12.832-7.218c-22.375-15.51-27.887-27.825-28.576-35.43-.735-8.114 3.588-12.552 3.773-12.736l.1-.074 11.566-5.91a11.641 11.641 0 0 1 16.237 6.389l10.7 29.425 11.861-8.749 5.06-1.994 4.081-5.458 11.212-6.539 8.328 7.496-10.474 20.948-5.819 1.482-1.234 3.178-.11.07Z"
      fill="#e6e6e6"
    />
    <path
      d="M305.792 77.964a21.845 21.845 0 1 1 1.11-18.61c.1.25.19.5.28.76a21.815 21.815 0 0 1-1.39 17.85Z"
      fill="#9e616a"
    />
    <path
      d="M308.931 60.114c-.94-2.63-.53-1.26-1.48-3.88a6.539 6.539 0 0 1-.27 3.88h-26.01v-15.12c7.62-3.02 15.07-5.59 19.57 0a15.12 15.12 0 0 1 15.12 15.12Z"
      fill="#2f2e41"
    />
    <path
      d="M299.712 102.144c-.02-.28-.05-.57-.09-.85a29.448 29.448 0 0 0-1.69-6c-.75-2.11-1.51-4.23-2.27-6.34a18.152 18.152 0 0 1-.85 11.52c-4.39-3.42-1.62-11.24-7.36-11.24-13.8 0-28-11.19-28-25 0-13.8 5.92-24.12 19.72-24.12 13.81 0 25.01 6.32 25.28 20.12-17 4-1.53 40.51-4.74 41.91Z"
      fill="#2f2e41"
    />
    <circle cx={261.176} cy={46.119} r={16} fill="#2f2e41" />
    <path
      d="M266.541 46.74c-2.74 3.19-7.03 4.43-10.9 6.09a45.855 45.855 0 0 0-26.64 33.5c-1.07 5.97-.93 12.15-2.62 17.97-1.68 5.82-5.84 11.53-11.81 12.54-4.04.69-8.09-.87-11.89-2.41q-3.135-1.26-6.27-2.53 3.27-8.204 6.53-16.41a83.161 83.161 0 0 1-12.11 14.17q-11.235-4.53-22.49-9.06c18.46.07 15.31-17.85 19.44-35.83 2.27-9.88 21.6-16.57 25.39-25.97a28.756 28.756 0 0 1 52.16-2.62c3.79 1.51 3.9 7.41 1.21 10.56Z"
      fill="#2f2e41"
    />
    <path
      d="m749.94 489.213-748.75.307a1.19 1.19 0 0 1 0-2.381l748.75-.307a1.19 1.19 0 0 1 0 2.38Z"
      fill="#cacaca"
    />
  </svg>
);
export default SvgTeacher1;
