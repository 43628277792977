import * as React from "react";
const SvgTeacher5 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="1em"
    height="1em"
    viewBox="0 0 655.67 413.648"
    {...props}
  >
    <path
      d="M142.128 276.16c-1.2 8.84-7.21 16.82-14.94 22.03a39.477 39.477 0 0 1-5.81 3.25 50.541 50.541 0 0 1-6.87 2.52 80.156 80.156 0 0 1-17.45 2.81h-.01c-4.93.32-9.89.36-14.8.39l-24.18.15-8.66.06c-8.63.05-17.54.05-25.51-3.26a3.676 3.676 0 0 1-.35-.15c-7.83-3.38-14.5-10.81-14.31-19.31a17.42 17.42 0 0 1 2.46-8.49 24.86 24.86 0 0 1 3.64-4.76c4.34-4.5 10.36-8.03 16-10.87 6.35-3.19 13.27-7.03 17.88-12.2.56-.63 1.08-1.27 1.56-1.93a19.592 19.592 0 0 0 3.09-6.2 17.22 17.22 0 0 0 .58-3.09c.64-6.6-2.57-12.67-6.66-18.1-.4-.54-.82-1.08-1.25-1.62-.92-1.15-1.87-2.28-2.81-3.36-.4-.46-.8-.93-1.21-1.4-1.12-1.3-2.25-2.63-3.33-3.99-.5-.64-.99-1.28-1.47-1.93a35.574 35.574 0 0 1-4.78-8.37 20.676 20.676 0 0 1-1.41-6.86c-.22-8.49 9.27-17.04 16.45-13.03 2.72-.26 5.45-.53 8.17-.81.64-.06 1.27-.13 1.91-.19.16-.02.32-.03.48-.05 4.32-.43 8.62-.86 12.93-1.3h.01c.97-.08 1.92-.18 2.87-.28h.01l20.71-2.07c.64-.06 1.28-.13 1.92-.19 1.42-.15 2.83-.29 4.25-.43 3.98-.39 8.66-.54 11.25 2.52 2.82 3.34 1.36 8.62-1.43 11.99-2.79 3.36-6.66 5.74-9.28 9.22a18.236 18.236 0 0 0-3.54 9.85c-.04.63-.06 1.28-.04 1.93a24.58 24.58 0 0 0 .37 3.99 28.312 28.312 0 0 0 1.26 4.76 31.825 31.825 0 0 0 4.03 7.77c5.88 8.24 14.59 13.92 22.32 20.45.31.26.61.52.91.79.73.62 1.44 1.27 2.15 1.93 6.5 6.07 12.06 13.46 12.98 22.18.03.29.06.59.07.89a22.893 22.893 0 0 1-.16 4.76Z"
      fill="#f2f2f2"
    />
    <path
      d="M77.351 125.942c1.67-2.04 3.33-4.1 5-6.15a49.28 49.28 0 0 0-8.358-13.738 3.394 3.394 0 0 0-5.908 1.855c-1.46 10.76-9.383 21.066-10.764 32.284a27.914 27.914 0 0 0 .49 9.6v.004a111.303 111.303 0 0 1-9.505-58.533 112.867 112.867 0 0 1 23.989-57.746 30.108 30.108 0 0 0 12.526-13.006c.373-.76.714-1.542 1.014-2.34a2.892 2.892 0 0 0-2.69-3.889 43.453 43.453 0 0 1-5.794-.34c1.027-1.254 3.533-4.336 5.42-6.657a4.033 4.033 0 0 0-.96-6l-.04-.024c-4.61-2.7-10.97-.83-14.3 3.34-3.34 4.17-3.97 10.02-2.81 15.23a40.339 40.339 0 0 0 5.58 12.44l.004.006c-.253.318-.518.627-.768.948a114.83 114.83 0 0 0-16.48 29.133c-.16.414-.302.834-.458 1.25l.002-.026.64-8.34a29.082 29.082 0 0 0 1.58-25.18c-2.711-6.542-7.799-12.05-12.272-17.705-5.363-6.78-16.346-3.788-17.29 4.806q-.015.124-.028.249a31.939 31.939 0 0 1 9.66 8.37c-3.24.5-6.49 1.01-9.73 1.51a47.41 47.41 0 0 0 1.25 7.09A48.803 48.803 0 0 0 51.82 65.572a115.47 115.47 0 0 0-6.255 29.466 110.202 110.202 0 0 0 .078 17.806c-.013-.07-.018-.141-.032-.212a29.147 29.147 0 0 0-9.95-16.83C27.612 89.19 16.07 86.97 7.677 81.341A4.758 4.758 0 0 0 .33 85.824q.108.931.252 1.858a31.815 31.815 0 0 1 13.25 10.1c-3.24.5-6.49 1.01-9.73 1.51l-.2.03a47.462 47.462 0 0 0 8.73 13.67 48.867 48.867 0 0 0 35.44 15.55h.014A115.996 115.996 0 0 0 59.51 158.48a114.355 114.355 0 0 0 9.123 14.03l.537 1.523c.12-.11.25-.22.37-.33l.016-.014a112.557 112.557 0 0 0 9.686 10.909c1.094 1.078 2.779-.607 1.685-1.685a110.114 110.114 0 0 1-9.617-10.85 48.875 48.875 0 0 0 12.76-21.66 31.7 31.7 0 0 1-7.72-.46c2.07-2.54 4.14-5.1 6.21-7.64a1.738 1.738 0 0 0 .13-.15c1.05-1.3 2.11-2.59 3.16-3.89.01-.5.01-.99 0-1.49a46.758 46.758 0 0 0-1.36-10.34 31.635 31.635 0 0 1-7.14-.49Zm-31.23-7.19c.01-.39.01-.78 0-1.18 0-.11-.016-.22-.019-.332.086.691.17 1.382.268 2.072l-.009-.02Z"
      fill="#f2f2f2"
    />
    <path
      fill="#3f3d56"
      d="m225.203 240.492-11.581 50.955-7.4 8.106h-5.339l22.004-92.646 1.088 15.739v.011l1.228 17.835z"
    />
    <path
      d="m280.937 292.566-67.94-.43-6.03-.04-4.3-.03-5.01-.03h-.04a2.765 2.765 0 0 0-1.94.8l-8.46 8.46a2.184 2.184 0 0 0-.45.64.981.981 0 0 0-.13.22 2.824 2.824 0 0 0 2.54 3.94h88.01a2.8 2.8 0 0 0 2.52-1.6l.69-1.5 1.42-3.05 1.61-3.44a2.742 2.742 0 0 0 .26-1.17 2.785 2.785 0 0 0-2.75-2.77Z"
      fill="#3f3d56"
    />
    <path
      d="m552.562 546.17-.695 1.506a2.799 2.799 0 0 1-2.513 1.598H461.34a2.819 2.819 0 0 1-2.536-3.938.84.84 0 0 1 .128-.22Z"
      transform="translate(-272.165 -243.176)"
      opacity={0.17}
      style={{
        isolation: "isolate",
      }}
    />
    <path
      opacity={0.17}
      style={{
        isolation: "isolate",
      }}
      d="M223.975 222.646v.011l-18.842 76.896h-4.25l22.004-92.646 1.088 15.739z"
    />
    <path
      d="m247.097 236.736-26.67-202.91a2.892 2.892 0 0 0-1.1-1.92l-18.36-22.98-1.65-1.27a2.929 2.929 0 0 0-2.14-.57l-2.76.37a2.894 2.894 0 0 0-2.5 3.24l27.64 210.24v.01l1.69 12.86 2.01 15.27v.02l3.12 23.76a2.832 2.832 0 0 0 1.25 2.02 2.873 2.873 0 0 0 2.33.42l1.75-.44a2.939 2.939 0 0 0 1.66-1.14l.78-1.11.36-.52 12.08-33.3a2.876 2.876 0 0 0 .51-2.05Z"
      fill="#3f3d56"
    />
    <path
      d="m473.128 252.103 33.547 263.164-.364.515-.778 1.107a2.93 2.93 0 0 1-1.66 1.14l-1.753.441a2.905 2.905 0 0 1-3.579-2.438l-34.455-262.156a2.896 2.896 0 0 1 2.493-3.248l2.768-.364a2.855 2.855 0 0 1 2.13.573Z"
      transform="translate(-272.165 -243.176)"
      opacity={0.17}
      style={{
        isolation: "isolate",
      }}
    />
    <path
      fill="currentColor"
      d="m201.951 16.453 17.803 25.411 21.16 161.001-11.472 22.759-27.491-209.171zM264.395 240.172l-6.55 7.206a2.074 2.074 0 0 1-2.221.288 6.595 6.595 0 0 1-2.804-1.922 95.877 95.877 0 0 0-15.671-14.249 6.596 6.596 0 0 1-2.18-2.608 2.103 2.103 0 0 1 .05-2.207c.065-3.942 2.285-6.385 6.6-7.261.757-.788 2.467-.488 3.999.707a159.149 159.149 0 0 1 17.717 16.108c1.349 1.426 1.805 3.12 1.06 3.938Z"
    />
    <path
      d="m247.558 224.663-5.514 6.064c-.19.21-.649.102-1.025-.24s-.526-.788-.336-.997l5.514-6.065c.19-.209.65-.102 1.025.24s.527.789.336.998ZM253.003 229.613l-5.514 6.065c-.19.21-.649.102-1.025-.24s-.526-.788-.336-.998l5.514-6.064c.19-.21.65-.102 1.025.24s.526.788.336.997ZM258.448 234.564l-5.514 6.064c-.19.21-.649.102-1.025-.24s-.526-.788-.336-.997l5.514-6.065c.19-.21.649-.102 1.025.24s.526.788.336.998Z"
      fill="#fff"
    />
    <path
      d="M655.67 306.88a1.186 1.186 0 0 1-1.19 1.19H1.19a1.19 1.19 0 0 1 0-2.38h653.29a1.187 1.187 0 0 1 1.19 1.19Z"
      fill="#ccc"
    />
    <path
      d="M127.118 248.33h2.17v-1.93h-32.3v-37.76h1.21c-.02-.65 0-1.3.04-1.93h-1.25v-33.15c-.64.06-1.28.13-1.92.19v32.96h-37.01v-29.26c-.64.06-1.27.13-1.91.19v29.07h-18.43c.48.65.97 1.29 1.47 1.93h16.96v37.76h-5.37c-.48.66-1 1.3-1.56 1.93h6.93v30.12h-23.37q.42.974.9 1.92h22.47v25.32h1.91v-25.32h37.01v25.32h1.92v-25.32h32.3v-1.92h-32.3a30.13 30.13 0 0 1 30.13-30.12Zm-32.05 30.12h-37.01v-30.12h37.01Zm-37.01-32.05v-.76a37.008 37.008 0 0 1 37.01-37v37.76Z"
      fill="#e6e6e6"
    />
    <path
      d="M154.423 250.333c-11.017-15.354-31.81-23.289-46.443-17.722-14.633 5.566-17.564 22.526-6.547 37.88s43.446 39.505 58.078 33.939 5.928-38.743-5.088-54.097Zm.067 47.225c-1.8 2.64-6.22 2.763-9.87.273s-5.149-6.648-3.347-9.289 6.22-2.763 9.87-.274 5.149 6.649 3.347 9.29Z"
      fill="#3f3d56"
    />
    <path
      d="m141.986 265.351-4.116 3.327a16.216 16.216 0 0 1-5.85 3.458 15.912 15.912 0 0 1-8.98-.534 21.5 21.5 0 0 1-10.538-7.49l2.966.662a23.118 23.118 0 0 1-3.792-6.093c-.695-2.057-.307-4.144 1.57-4.708 2.15-.645 5.609.805 7.413-.237 3.12-1.802-2.01-8.513.834-10.583 1.657-1.206 4.971-.012 7.734 1.17a43.568 43.568 0 0 1 9.694 5.177c4.908 3.79 7.626 9.765 5.621 13.223a10.15 10.15 0 0 1-2.556 2.628Z"
      fill="currentColor"
    />
    <path
      d="M140.975 276.865c-2.4.328-5.175-1.516-7.49-3.218l-.59-.771a12.508 12.508 0 0 1-.598-4.582 3.18 3.18 0 0 1 2.703-2.903c1.407-.186 3.286.421 4.215-.393 1.798-1.574-2.37-5.914-.465-7.387 1.07-.827 3.342.233 4.322 1.654a7.478 7.478 0 0 1 1.058 4.41l.095 6.801a11.419 11.419 0 0 1-.4 3.976 3.522 3.522 0 0 1-2.85 2.413Z"
      fill="#2f2e41"
    />
    <path
      d="M141.577 284.42c-3.326-.983-8.398-4.41-12.717-7.437l-1.286-1.119a23.377 23.377 0 0 1-3.396-5.047c-.498-1.365.144-2.163 2.352-1.503 1.955.583 5.033 2.242 5.944 1.915 1.76-.632-6.72-7.395-4.745-7.864 1.108-.262 5.012 2.08 7.227 4.083a17.546 17.546 0 0 1 3.971 5.123l3.883 7.057a10.837 10.837 0 0 1 1.603 3.875c.026 1.001-.818 1.512-2.836.916Z"
      fill="#ff6584"
    />
    <path
      d="m93.842 293.43 61.74 8.21a1.326 1.326 0 0 1-.21 2.64l-60.218-1.59Z"
      fill="#3f3d56"
    />
    <path
      d="M76.141 300.527a30.252 30.252 0 0 1 4.673-5.376c.9-.811 1.85-1.564 2.806-2.287.33-.262.673-.517 1.006-.77.794-.603 1.588-1.207 2.391-1.813a232.62 232.62 0 0 1 2.032-1.544c.99-.754 2.105-1.544 3.341-1.37a3.919 3.919 0 0 1 2.63 2.083 13.193 13.193 0 0 1 1.184 3.416c.67 2.72 1.33 5.444 2.001 8.165l.995 1.066a17.11 17.11 0 0 1-4.886 3.376 6.104 6.104 0 0 1-4.791-.085 5.142 5.142 0 0 1-.926-.576c-1.254-.991-2.026-2.548-3.13-3.739a8.339 8.339 0 0 0-.74-.692 6.777 6.777 0 0 0-5.572-1.494 6.26 6.26 0 0 0-3.014 1.64Z"
      fill="#ccc"
    />
    <path
      d="M76.141 300.527a30.252 30.252 0 0 1 4.673-5.376c.9-.811 1.85-1.564 2.806-2.287.33-.262.673-.517 1.006-.77.794-.603 1.588-1.207 2.391-1.813a14.579 14.579 0 0 1 .711 1.345c1.999 4.295 2.144 9.092 1.795 13.762a5.142 5.142 0 0 1-.926-.576c-1.254-.991-2.026-2.548-3.13-3.739a8.339 8.339 0 0 0-.74-.692 6.777 6.777 0 0 0-5.572-1.494 6.26 6.26 0 0 0-3.014 1.64Z"
      fill="currentColor"
    />
    <path
      d="M568.504 115.168c-2.302-4.952 1.352-10.481 2.656-15.813 2.434-9.948-4.423-20.719-13.716-24.166-9.294-3.447-19.974-.566-27.957 5.466-7.826 5.914-13.328 8.7-18.719 17.054-1.807 2.802-3.722 11.5-4.064 14.856-.342 3.356 1.394 7.2 4.56 7.937-1.28 2.713.936 5.84 3.791 5.289 6.058-1.17 17.185 2.046 17.185 2.046l31.122 1.782c2.659.152 5.417.29 7.805-1.178a5.306 5.306 0 0 0 2.54-3.636c.608-3.93-3.615-6.222-5.203-9.637ZM562.14 293.166c-18.432 17.37-38.006 16.322-58.607-1.56-11.366-.302-20.4-8.521-20.14-18.323s9.72-17.529 21.086-17.226l58.606 1.559c11.366.302 20.401 8.522 20.14 18.323s-9.72 17.529-21.086 17.227Z"
      fill="#2f2e41"
    />
    <path
      d="m576.338 158.5-2.27 15.11-3.6 23.94-.61 8.87-4.48 65.01s-34.62-2.84-43.82 0c-9.2 2.85-31.56-8.88-31.56-8.88 8.03-7.9 5.86-36.93 5.86-36.93l-.09-71.91 20.31-6.83 6.69-13.17h24l6 14 10.89 4.98 4.96 2.28Z"
      fill="#3f3d56"
    />
    <path
      fill="#ffb6b6"
      d="m480.718 395.97-11.622 1.898-12.855-43.923 17.153-2.802 7.324 44.827z"
    />
    <path
      d="M476.1 304.381c21.668 5.328 37.668-.672 50.847.106 7.49-12.64 31.403-28.696 18.356-35.454-7.266-3.764-81.128-12.44-90.825-2.427-24.933 25.75 6.024 107.066 6.024 107.066l17.218-.222 5.501-7.852-4.431-8.558 4.192-6.996-4.797-7.207Z"
      fill="#2f2e41"
    />
    <path
      fill="#ffb6b6"
      d="m587.819 395.97 11.621 1.898 12.855-43.923-17.152-2.802-7.324 44.827z"
    />
    <path
      d="M592.437 282.381c-11.992 17.586-31.12 20.463-50.847 22.106-7.491-12.64-31.403-28.696-18.356-35.454 7.266-3.764 81.128-34.44 90.824-24.427 24.934 25.75-6.023 129.066-6.023 129.066l-17.218-.222-5.501-7.852 4.43-8.558-4.191-6.996 4.797-7.207ZM588.837 379.651l17.233 3.53-1.946 7.267 13.144 15.455a4.7 4.7 0 0 1-4.795 7.584l-20.194-5.407-1.556-8.12-3.284 6.824-7.614-2.038ZM488.711 404.746l-7.614 2.038-3.283-6.824-1.556 8.12-20.194 5.407a4.7 4.7 0 0 1-4.796-7.584l13.145-15.455-1.946-7.268 17.233-3.529Z"
      fill="#2f2e41"
    />
    <circle cx={535.76} cy={106.663} r={20.292} fill="#ffb6b6" />
    <path
      d="M524.166 100.298a25.968 25.968 0 0 1-14.979-4.726l-.146-.102a19.738 19.738 0 0 1 19.738-19.738h8.057a19.581 19.581 0 0 1 19.559 19.645 4.51 4.51 0 0 1-4.454 4.446l-27.312.47c-.154.003-.31.005-.463.005Z"
      fill="#2f2e41"
    />
    <path
      d="M571.21 326.619h-61.1a6.183 6.183 0 0 1-6.177-6.177v-55.904a6.183 6.183 0 0 1 6.176-6.176h61.1a6.183 6.183 0 0 1 6.177 6.176v55.904a6.183 6.183 0 0 1-6.176 6.177Z"
      fill="#e6e6e6"
    />
    <path
      d="M566.915 321.47h-52.51a5.49 5.49 0 0 1-5.485-5.483v-46.994a5.49 5.49 0 0 1 5.484-5.484h52.511a5.49 5.49 0 0 1 5.484 5.484v46.994a5.49 5.49 0 0 1-5.484 5.484Z"
      fill="#fff"
    />
    <path
      d="M561.677 277.191h-42.034a1.37 1.37 0 1 1 0-2.742h42.034a1.37 1.37 0 1 1 0 2.742ZM561.677 282.608h-42.034a1.37 1.37 0 0 1 0-2.742h42.034a1.37 1.37 0 0 1 0 2.742ZM537.863 288.025h-18.22a1.37 1.37 0 1 1 0-2.742h18.22a1.37 1.37 0 0 1 0 2.742Z"
      fill="currentColor"
    />
    <path
      d="M561.677 299.37h-42.034a1.37 1.37 0 1 1 0-2.741h42.034a1.37 1.37 0 1 1 0 2.741ZM561.677 304.787h-42.034a1.37 1.37 0 0 1 0-2.742h42.034a1.37 1.37 0 0 1 0 2.742ZM537.863 310.204h-18.22a1.37 1.37 0 1 1 0-2.742h18.22a1.37 1.37 0 1 1 0 2.742Z"
      fill="#e4e4e4"
    />
    <path
      d="m580.768 199.71-18.38.15-1.351 32.715-14.935 18.284a9.994 9.994 0 1 0 11.543 11.343l20.573-28.52ZM486.647 201.194l-18.842-8.808-20.575 25.472-22.887 5.789a9.994 9.994 0 1 0 2.513 15.986l33.515-10.65Z"
      fill="#ffb6b6"
    />
    <path
      d="m545.913 125.389 7.025-8.765a5.072 5.072 0 0 0 1.41-2.826c.037-1.071-.973-2.218-1.99-1.882-.946.311-6.39-13.33-7.386-13.328-1.03.003-1.367-1.372-1.38-2.403-.05-3.908 3.126.288 4.176-3.476s5.144-.528 8.671-2.209 8.321-.917 10.502 2.326c1.617 2.404 1.557 5.528 1.343 8.417a132.181 132.181 0 0 1-3.018 19.915c-.371 1.61-.924 3.408-2.407 4.134a5.732 5.732 0 0 1-2.571.385l-10.817-.054"
      fill="#2f2e41"
    />
    <circle cx={562.768} cy={77.709} r={10} fill="#2f2e41" />
    <path
      d="M585.258 199.9c-1.08 1.88-3.38 2.63-5.48 3.21-2.84.79-3.67 1.59-6.51 2.38-1.13.32-2.27.64-3.41.93a48.242 48.242 0 0 1-5.69 1.17c-1.61.23-3.51.23-4.56-1.02a5.255 5.255 0 0 1-.79-3.63q.615-23.04 1.24-46.08l.87.3a11.318 11.318 0 0 1 1.22-.7 13.695 13.695 0 0 1 6.47-1.49 13.152 13.152 0 0 1 3.79.59 13.767 13.767 0 0 1 8.54 7.82 24.047 24.047 0 0 1 1.4 8.69c.01.45.01.9.02 1.35.06 3.84-1.89 7.68-1.83 11.52a9.025 9.025 0 0 0 .44 3.19c.4 1.02 1.18 1.87 1.48 2.93.3 1.05-.19 2.49-1.28 2.57a4.196 4.196 0 0 1 4.08 6.27ZM495.852 153.796a13.611 13.611 0 0 1 12.376 16.933c-.835 3.295-2.86 6.142-4.844 8.904l-6.721 9.354a8.957 8.957 0 0 0-1.549 2.819c-.282 1.06-.17 2.21-.558 3.238s-1.635 1.889-2.561 1.3a4.2 4.2 0 0 1-.452 7.47c-1.994.87-4.285.093-6.317-.684l-8.254-3.16a68.467 68.467 0 0 1-8.561-3.73c-1.43-.785-2.954-1.914-3.056-3.541-.079-1.266.992-2.239 1.529-3.388 6.722-14.392 16.607-25.844 28.884-35.602Z"
      fill="#3f3d56"
    />
    <circle cx={511.721} cy={328.071} r={14.189} fill="#fff" />
    <path
      d="M511.721 342.434a14.362 14.362 0 1 1 14.362-14.363 14.379 14.379 0 0 1-14.362 14.363Z"
      fill="currentColor"
    />
    <path
      d="M510.659 333.742c-.03 0-.06 0-.091-.002a1.856 1.856 0 0 1-1.376-.715l-2.37-3.049a1.856 1.856 0 0 1 .325-2.605l.085-.066a1.856 1.856 0 0 1 2.606.325 1.354 1.354 0 0 0 2.05.1l4.815-5.083a1.859 1.859 0 0 1 2.625-.071l.079.075a1.856 1.856 0 0 1 .07 2.625l-7.471 7.886a1.854 1.854 0 0 1-1.347.58Z"
      fill="#fff"
    />
    <path
      d="m410.551 249.739-35.333-43.264a7.216 7.216 0 0 1 1.024-10.142l9.429-7.7a7.216 7.216 0 0 1 10.142 1.023l35.333 43.263a7.216 7.216 0 0 1-1.023 10.142l-9.43 7.7a7.216 7.216 0 0 1-10.142-1.022Z"
      fill="#3f3d56"
    />
    <path
      d="m429.378 233.75-9.46-11.58-4.93-6.03-20.95-25.65a3.876 3.876 0 0 0-5.46-.55l-9.43 7.7a3.877 3.877 0 0 0-.55 5.46l.02.03 17.69 21.66 17.62 21.58a3.897 3.897 0 0 0 5.47.55l9.42-7.7a3.899 3.899 0 0 0 .56-5.47Z"
      fill="currentColor"
    />
    <path
      d="M401.768 213.71a13.946 13.946 0 0 1-5.46 11.08l-17.69-21.66a13.986 13.986 0 0 1 23.15 10.58Z"
      fill="#3f3d56"
    />
    <path
      d="M404.38 206.51c.48 1.723-.758 3.787-3.484 5.816-.545.406-1.32.598-1.774 1.185a8.904 8.904 0 0 1-8.541 3.602c-.19-.121-.38-.247-.568-.376l.105-.028a35.035 35.035 0 0 0 9.172-3.907c.458-.29 1.168-.4 1.299-.89 1.049-3.933 3.402-4.217 3.296-5.263-.155-1.53-2.43-2.504-5.54-2.808q-.46-.045-.943-.07c-.128-.18-.259-.357-.396-.53q.717.022 1.39.087c3.381.331 5.506 1.462 5.985 3.183Z"
      fill="#fff"
    />
    <circle cx={389.702} cy={205.622} r={3.456} fill="#fff" />
    <circle cx={407.88} cy={227.568} r={0.908} fill="#fff" />
    <circle cx={422.88} cy={231.568} r={0.908} fill="#fff" />
    <circle cx={389.88} cy={194.568} r={0.908} fill="#fff" />
    <circle cx={415.88} cy={237.568} r={0.908} fill="#fff" />
    <circle cx={394.049} cy={209.369} r={0.355} fill="#f0f0f0" />
    <circle cx={400.049} cy={216.369} r={0.355} fill="#f0f0f0" />
    <circle cx={382.049} cy={204.369} r={0.355} fill="#f0f0f0" />
    <path d="M419.918 222.17a3.995 3.995 0 1 1-4.93-6.03Z" fill="#ff6584" />
  </svg>
);
export default SvgTeacher5;
