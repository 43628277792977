import * as React from "react";
const SvgArt = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width="1em"
    height="1em"
    viewBox="0 0 1150.082 803.243"
    aria-labelledby="calloutImgTitle"
    role="img"
    {...props}
  >
    <title id="calloutImgTitle">
      Mulher segurando uma folha grande e ela esta andando pela floresta
    </title>
    <path
      d="M664.01 510.834a446.554 446.554 0 0 0-21.96-119.54s.01-.01 0-.01q-4.17-12.705-9.14-25.2-1.86-4.695-3.83-9.36a447.764 447.764 0 0 0-37.14-69.97q-7.68-11.85-16.13-23.2a454.542 454.542 0 0 0-43.34-50.13q-9.585-9.585-19.64-18.51c-.97-.87-1.94-1.73-2.92-2.57a447.504 447.504 0 0 0-78.42-54.97c-.4-.24-.79-.45-1.19-.66q-8.565-4.71-17.34-9.02c-.6-.3-1.19-.6-1.79-.88q-7.515-3.675-15.18-7.05c-.81-.37-1.63-.73-2.45-1.08-.02-.01-.03-.02-.05-.02q-2.16-.945-4.32-1.86l-1.14-.48h-.01a447.603 447.603 0 0 0-163.44-34.81q-5.43-.135-10.89-.13h-97.54a85.06 85.06 0 0 0-22.42 2.99l-.44.12a85.487 85.487 0 0 0-62.48 82.22v371.01a13.626 13.626 0 0 0 4.97 10.53c.02.02.04.03.05.04a13.596 13.596 0 0 0 8.66 3.1h600.83a19.214 19.214 0 0 0 19.19-19.19q0-10.725-.5-21.37Z"
      fill="#f2f2f2"
    />
    <path
      d="M440.878 454.745a217.016 217.016 0 0 1-22.306 96.153H44.49a13.177 13.177 0 0 1-13.177-13.177V350.637c57.476-105.796 189.835-144.967 295.631-87.49a218.005 218.005 0 0 1 113.934 191.599Z"
      fill="#2f2e41"
    />
    <path
      d="M664.01 419.954v112.25a18.694 18.694 0 0 1-18.693 18.694H43.661a13.083 13.083 0 0 1-13.082-13.079v-.133c0-93.495 290.799-173.243 373.483-204.21a218.26 218.26 0 0 1 259.949 86.478Z"
      fill="#3f3d56"
    />
    <circle
      cx={589.881}
      cy={258.706}
      r={54.315}
      transform="rotate(-80.783 548.971 249.184)"
      fill="#ff6584"
    />
    <path
      d="M281.36 222.794q0 11.31-.94 21.59c-8.1 89.43-67.58 121.35-139.74 121.35-1.81 0-3.6-.02-5.39-.07-3.6-.07-7.17-.24-10.69-.49-32.39-2.29-61.43-11.82-83.32-31.11a105.415 105.415 0 0 1-10.47-10.65C11.53 300.824 0 268.124 0 222.794c0-73.67 68-161.27 109.45-207.82A41.69 41.69 0 0 1 139.61.964h.4a41.617 41.617 0 0 1 31.9 14.01 705.974 705.974 0 0 1 52.67 66.54c30.11 43.55 56.78 95.15 56.78 141.28Z"
      fill="currentColor"
    />
    <path
      fill="#3f3d56"
      d="m135.55 349.524 51.46-71.9-51.59 79.79-.13 8.25-1.15 70.75-.42 25.95h-14.21l.98-18.74 4.11-78.45 5.55-105.99-.05-.83.1-.15.04-.85.48-9.16-51.71-79.98 51.87 72.47.12 2.12 4.19-80.08-41.9-78.23-2.37-4.42 2.81 4.3 42.01 64.28.62-23.91 1.14-43.36 2.11-80.42h.4l-.44 80.42-.18 32.16 27.3-32.16 16.77-19.75-13.84 19.75-30.42 43.43-1.16 71.71 41.15-68.81-41.32 79.36-.65 39.87 59.73-95.79-55.35 101.27-4.61 8.43-1.44 88.67z"
    />
    <path
      d="M510.74 174.234q0 9.51-.83 18.11c-6.78 71.37-54.49 96.87-112.34 96.87-1.45 0-2.9-.02-4.33-.05q-4.35-.09-8.6-.41a143.902 143.902 0 0 1-25.06-3.86c-20.44-5.08-38.16-14.91-51.26-30.76-14.98-18.13-23.91-44.13-23.91-79.9 0-26.56 10.99-55.37 26.12-82.45 16.52-29.57 37.98-57.07 55.45-77.33A41.525 41.525 0 0 1 396.61.004h.4a41.554 41.554 0 0 1 32.16 14.45c33.48 38.81 81.57 104.22 81.57 159.78Z"
      fill="currentColor"
    />
    <path
      fill="#3f3d56"
      d="m393.45 276.174 41.39-57.83-41.49 64.18-.11 6.64-.47 28.95-.31 19.56-.48 29.27h-11.43l1.34-25.61 1.77-33.83.98-18.75 4.46-85.25-.03-.66.07-.12.42-8.06-41.59-64.34 41.72 58.3.1 1.71 3.37-64.42-5.13-9.59h-.01l-30.47-56.89 36.05 55.17 3.01-114.6h.4l-.48 86.35 35.45-41.75-35.59 50.82-.4 24.34-.54 33.34 15.72-26.29 17.38-29.06-15.59 29.94-17.65 33.9-.52 32.07 35.51-56.95 12.54-20.1-11.35 20.76-36.88 67.48-1.16 71.32z"
    />
    <path
      d="M743.87 294.454c0 57.38-34.11 77.41-76.19 77.41q-1.455 0-2.91-.03c-1.95-.05-3.88-.13-5.79-.28a90.392 90.392 0 0 1-26.07-5.47c-24.59-9.45-41.41-31.33-41.41-71.63a69.221 69.221 0 0 1 .44-7.7c1.41-12.87 6.17-26.3 12.67-39.35a225.587 225.587 0 0 1 12.38-21.58c5.76-8.94 11.95-17.41 17.96-25.04a41.429 41.429 0 0 1 31.88-15.85c.14 0 .28-.01.43 0a41.429 41.429 0 0 1 33.16 15.85c20.65 26.23 43.45 62.28 43.45 93.67Z"
      fill="currentColor"
    />
    <path
      d="m664.91 363.084 27.86-38.93-27.93 43.21-.07 4.47-.79 48.07-.07 4.29h-7.69l.76-14.51 2-38.13 3-57.39-.03-.45.05-.08.29-5.42-28-43.32 28.08 39.25.07 1.15 2.27-43.37-23.97-44.76 24.27 37.16 1.82-69.39c.14 0 .28-.01.43 0l-.28 50.36 23.87-28.11-23.97 34.21-.63 38.84 22.29-37.27-22.38 42.98-.35 21.59 32.35-51.87-32.47 59.4Z"
      fill="#3f3d56"
    />
    <path
      d="M182.228 400.316c-3.372 5.378-7.746 5.252-11.69 2.78s-5.965-6.356-2.593-11.734 14.802-7.744 14.802-7.744 2.853 11.32-.52 16.698ZM77.268 414.996c-3.372 5.378-7.747 5.252-11.69 2.78s-5.965-6.356-2.593-11.734 14.802-7.744 14.802-7.744 2.852 11.32-.52 16.698ZM449.358 389.123c.624 6.318-2.914 8.894-7.546 9.352s-8.606-1.377-9.23-7.695 6.97-15.182 6.97-15.182 9.182 7.208 9.806 13.525ZM328.292 310.77c-3.372 5.378-7.747 5.251-11.69 2.779s-5.965-6.355-2.593-11.734 14.802-7.743 14.802-7.743 2.852 11.319-.52 16.697ZM607.207 393.71c-3.371 5.378-7.746 5.252-11.69 2.78s-5.964-6.356-2.593-11.734 14.803-7.744 14.803-7.744 2.852 11.32-.52 16.698Z"
      fill="currentColor"
    />
    <path
      d="M1080.866 474.243c0 176.18-79.992 315.164-178.5 319-128.462 5.004-296.357-123.085-178.5-319 90.818-150.967 79.917-319 178.5-319s178.5 142.822 178.5 319Z"
      fill="#f2f2f2"
    />
    <path
      d="M1141.4 446.624c-15.94 19.81-37.71 43.34-61.38 58.8-11.26 7.36-22.96 12.9-34.66 15.34-61.39 12.82-90.44-19.2-99.84-64.21-.22-1.05-.43-2.09-.61-3.13q-.6-3.15-1.01-6.26c-5.61-41.23 10.33-77.5 67.42-89.42 17.67-3.69 38.14-.91 58.33 4.92 21.3 6.15 42.28 15.69 59.35 24.6a39.02 39.02 0 0 1 20.07 25.93c.03.13.06.26.08.39a39.004 39.004 0 0 1-7.75 33.04Z"
      fill="currentColor"
    />
    <path
      d="m1078.98 427.694-35.75 6.86 37.01 12.81 7.84 2.71-7.82-2.2-43.16-12.15-23.18 4.45 62.73 23.03-70.82-21.47-51.54 9.89 47.88 21.11-52.47-20.23-4.79.92-21.44 4.11v.01l-22.71 4.35-8.64 1.66-3.43.66-1.72-8.24 3.49-.54 7.17-1.11 26.2-4.05 20.07-3.11 62.08-9.61.47-.12.1.04 5.86-.91 40.09-39.64-35.72 38.82-1.21.33 46.9-7.26 15.42-12.92 27.12-22.73-26.73 26.68-7.59 7.58 8.14-1.48 70.24-12.75c.03.13.06.26.08.39l-57.67 11.71 35.4 19.25-41.96-17.99Z"
      fill="#3f3d56"
    />
    <path
      d="M928.83 321.974a.144.144 0 0 0-.02-.07 10.532 10.532 0 0 0-2.28-4.41 10.66 10.66 0 0 0-7.56-3.66c-.12 0-.23-.01-.35-.01a10.87 10.87 0 0 0-3.29.48 10.664 10.664 0 0 0-7.41 8.97l-.62 12.89-1.87 39.09-.25 5.05-3.02 62.97a13.154 13.154 0 0 0-4.53 11.05 13.357 13.357 0 0 0 1.97 5.99 13.39 13.39 0 0 0 5.33 4.85 13.374 13.374 0 0 0 5.97 1.41 13.303 13.303 0 0 0 12.48-8.78c.03-.08.06-.17.09-.25v-.01a13.215 13.215 0 0 0 .36-7.26 14.002 14.002 0 0 0-1.04-2.88 13.343 13.343 0 0 0-1.01-1.68l4.11-67.8 2.47-40.86.65-10.75.03-.46a10.433 10.433 0 0 0 .08-1.67.272.272 0 0 0 0-.09 10.723 10.723 0 0 0-.29-2.11Z"
      fill="#ffb6b6"
    />
    <path
      d="M929.76 331.664a19.1 19.1 0 0 0-.44-4.08c-.08-.43-.19-.86-.31-1.28a20.317 20.317 0 0 0-10.04-12.47 20.305 20.305 0 0 0-13.22-2.06c-12.82 2.33-20.47 6.61-22.74 12.72-1.6 4.31-.78 10.08 1.26 16.18v.01a95.285 95.285 0 0 0 6.68 14.87c.49.89.98 1.77 1.47 2.63a180.892 180.892 0 0 0 10.76 16.62l.12.17 2.13.28 2.69.35 17.77 2.31 1.12.15.06-.49a333.301 333.301 0 0 0 2.69-45.91Z"
      fill="#ff6584"
    />
    <ellipse cx={851.866} cy={786.243} rx={159} ry={17} fill="#3f3d56" />
    <path
      fill="#ffb6b6"
      d="m840.404 761.577-16.871-.001-8.026-65.073 24.9.001-.003 65.073z"
    />
    <path
      d="m849.03 788.164-1.58-18.91-1.33-16-5.72-.34-18.07-1.08-2.88-.17v11.43l-9.78 6.75-5.16 3.57-10.42 7.19a7.138 7.138 0 0 0 4.06 13.01h31.75l4.47-9.23 1-2.08.47 2.5v.01l1.67 8.8h11.97Z"
      fill="#2f2e41"
    />
    <path
      fill="#ffb6b6"
      d="m917.951 748.216-15.776 5.979-30.569-58.005 23.283-8.824 23.062 60.85z"
    />
    <path
      d="m936.3 771.834-15.96-33.43-5.46 1.71-17.28 5.4-2.76.86 4.05 10.68-17.5 25.36a7.137 7.137 0 0 0 3.24 10.69 6.978 6.978 0 0 0 4.67.19h.03a3.54 3.54 0 0 0 .46-.15l29.69-11.25.97-10.99.14-1.52.97 1.58 5.04 8.23 11.19-4.24Z"
      fill="#2f2e41"
    />
    <path
      d="M951.358 642.123c.624 6.318-2.914 8.894-7.546 9.352s-8.606-1.377-9.23-7.695 6.97-15.182 6.97-15.182 9.182 7.208 9.806 13.525ZM917.268 562.996c-3.372 5.378-7.747 5.252-11.69 2.779s-5.965-6.355-2.593-11.733 14.802-7.744 14.802-7.744 2.852 11.32-.52 16.698Z"
      fill="currentColor"
    />
    <path
      d="M874.61 590.048s73.856 116.282 65.68 121.497-74.42 25.653-80.34 21.565-10.711-137.847-10.711-137.847Z"
      fill="#2f2e41"
    />
    <path
      d="M829.578 410.503c10.853.423 55.461 6.885 54.475 8.576a35.631 35.631 0 0 1-3.242 4.228s2.255 10.29 1.268 11.276 19.31 24.243 7.47 74.139-10.57 84.427-10.57 84.427 4.792 146.304-8.88 149.264-67.514.986-70.051-4.793 7.752-185.205 7.752-185.205 1.128-90.206 3.806-101.2 11.264-40.861 17.972-40.712Z"
      fill="#2f2e41"
    />
    <circle cx={882.009} cy={252.972} r={33.968} fill="#ffb6b6" />
    <path
      d="M916.048 298.992c4.792 4.792 25.511 24.524 2.96 59.48s-36.929 60.325-36.929 60.325l-56.097-6.625 8.598-34.109s-4.228-26.92 10.148-40.31 30.163-35.379 30.163-35.379l8.141-1.487s28.224-6.688 33.016-1.895Z"
      fill="#ff6584"
    />
    <path
      d="M899.134 349.45s-79.212 61.031-84.287 39.607c-3.297-13.924.551-66.256 2.731-88.554l16.015.18s-3.382 49.19 3.383 54.829c0 0 24.51-23.668 29.592-23.321s32.566 17.26 32.566 17.26Z"
      fill="#ffb6b6"
    />
    <path
      d="M916.048 311.395s-10.756 49.933-42.47 58.108l-37-15s47.052-50.155 63.26-50.72 16.21 7.612 16.21 7.612Z"
      fill="#ff6584"
    />
    <circle cx={826.578} cy={295.503} r={13} fill="#ffb6b6" />
    <path
      d="M928.897 260.849c-.098-8.413-.206-16.92-2.14-25.075s-5.902-16.05-12.327-20.885c-6.426-4.836-15.552-6.002-22.162-1.46-4.925-6.344-13.44-8.77-20.973-7.054s-14.043 7.113-18.544 13.81-7.16 14.625-8.961 22.649c-.64 2.849-.908 6.485 1.385 8.082 1.798 1.253 4.17.48 6.178-.326l16.568-6.654c5.584 7.635 13.629 14.6 12.52 24.225-1.109 9.634-7.544 19.759-10.947 28.755l14.243-5.14c1.52-2.538 5.288-6.53 5.019-9.479a23.983 23.983 0 0 1 2.097 6.933c.002.02 0 .04.003.059l26.422-9.537c3.144-1.135 6.446-2.38 8.617-5.068 2.96-3.665 3.058-8.988 3.002-13.835Z"
      fill="#2f2e41"
    />
  </svg>
);
export default SvgArt;
